import "./GameCard.css";

export const GameCard = (props) => {
  return (
    <div className="game-card">
      <header>
        <h1 className="game-card-title">
          <a className="game-card-link" href={props.url}>
            {props.title}
          </a>
        </h1>
        <p>Released: {props.year}</p>
      </header>
      <img src={props.image} />
      <ul className="game-card-list">
        <li>OpenCritic Rating: {props.rating}</li>
        <li>Time to beat: {props.timeToBeat}</li>
      </ul>
    </div>
  );
};
